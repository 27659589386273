<template>
<div class="m-2">
  <div>
  Template: {{ templateId }}
  </div>
  <div class="form-inline sub-mt-2 sub-mr-2">
    <div>Manage Assets</div>
    <div class="border" v-for="a in assets" :key="a.id">
      {{ a.id }} : {{ a.url }}
    </div>
    <button class="btn btn-primary" @click="uploadAsset()">Upload Asset</button>
  </div>
  <div>
    <div class="position-sticky">
      JSON
      <button class="btn btn-primary" @click="loadFile()" v-if="!fileHandle">Connect File</button>
      <button class="btn btn-primary" @click="loadFile()" v-else>Reload {{fileHandle.name}}</button>
      <b-alert variant="success" :show="successMsg != null" dismissible>
        {{ successMsg }}
      </b-alert>
      <b-alert variant="danger" :show="errorMsg != null" @click="errorMsg = null" dismissible>
        {{ errorMsg }}
      </b-alert>
    </div>
    <div>
      Contents of the file:
    </div>
    <pre ref="fileContent"></pre>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("teditor");
import { db, storage } from "@/services/db";

export default {
  data() {
    return {
      userId: "etienne",
      templateId: "etienne-1",
      fileHandle: null,
      templateJSON: null,
      assetName: null,
      assets: [],
      ref: null,

      errorMsg: null,
      successMsg: null,

      mimeTypeMap: {
        "jpeg": "image/jpeg",
        "jpg": "image/jpeg",
        "gif": "image/gif",
        "png": "image/png",
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.ref = storage.ref();
      await this.$bind("assets", db.collection(`LiveStudioTemplates/${this.templateId}/files`));
    },
    async loadFile() {
      log.log("loadFile");
      this.successMsg = null;
      this.errorMsg = null;

      try {
        if (!this.fileHandle) {
          let fileHandle;
          [fileHandle] = await window.showOpenFilePicker();
          this.fileHandle = fileHandle;
        }
        let file = await this.fileHandle.getFile();
        let text = await file.text();
        this.$refs.fileContent.textContent = text;
        this.templateJSON = JSON.parse(text);
        await this.uploadTemplate(this.templateId, this.templateJSON);
      } catch (e) {
        this.errorMsg = e.toString();
      }
    },
    async uploadAsset() {
      try {
        let fileHandle;
        [fileHandle] = await window.showOpenFilePicker();
        log.log("uploadAsset", fileHandle);
        let name = fileHandle.name;
        let ext = name.split('.').pop();
        let metadata = {
          contentType: this.mimeTypeMap[ext] || "image/*",
        };
        let snap = await this.ref.child(`studio-custom/${this.userId}/${name}`).put(await fileHandle.getFile(), metadata);
        let url = await snap.ref.getDownloadURL();
        await db.collection(`LiveStudioTemplates/${this.templateId}/files`).doc(name).set({url});
      } catch (e) {
        this.errorMsg = e.toString();
      }
    },
    async uploadTemplate(templateId, templateJSON) {
      await db.collection("LiveStudioTemplates").doc(templateId).set(templateJSON);
      this.successMsg = `Template ${templateId} successfully updated.`;
    }
  }
}
</script>

<style>

</style>